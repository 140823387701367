import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["template", "confirmForm"]

  show(event) {
    event.preventDefault();
    var wrapper = document.createElement('div')
    wrapper.className = "fixed inset-0 bg-black-translucent z-50"
    wrapper.id = "modal_wrapper"

    var modal = document.createElement('article')

    let width = this.data.get("width") || "1/3";
    let widthClass = "w-" + width;

    let marginTopClass = this.data.get("margin-top") || "mt-10";

    modal.className = "modal " + widthClass + " " + marginTopClass;

    

    modal.setAttribute("data-controller", "modal");
    modal.setAttribute("data-modal-confirm-params", this.element.getAttribute("data-modal-confirm-params"));

    modal.innerHTML = this.templateTarget.innerHTML
    wrapper.appendChild(modal)
    document.body.appendChild(wrapper)

    let marginTop = window.getComputedStyle(modal).marginTop;
    modal.style = "max-height: calc(100vh - " + marginTop + " - 20px);"
  }

  confirm(event) {
    event.preventDefault();
    let form = event.target.form;
    let paramsConfig = JSON.parse(this.data.get("confirm-params"));

    for (const [param, attribute] of Object.entries(paramsConfig)) {
      var input = document.createElement('input');
      input.type = 'hidden';
      input.name = param;
      input.value = document.querySelector('[' + attribute + ']').getAttribute(attribute);
      form.appendChild(input);
    }

    form.submit();
  }

  close(event) {
    event.preventDefault();
    var modal = event.target.closest("article");
    var wrapper = document.querySelector("#modal_wrapper");
    modal.remove();
    wrapper.remove();
  }
}
